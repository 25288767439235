.mobileAppContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 32px;
    height: 100vh;
}

@media (max-width:900px) {
    .mobileAppWrapper {
        margin-top: 64px;
    }

    .mobileAppContainer {
        padding-left: 16px;
        padding-right: 16px;
        align-items: center;
        height: 50vh
    }

    .mobileParag {
        text-align: justify;
    }

    .appImageContainer {
        margin-top: 64px;
    }

}



.mobileApp {
    margin-left: 0;
    color: #074744;
    font-weight: 500;
    font-size: 40px;
    text-align: center;
}

.mobileParag {
    margin: 0;
    font-size: 1.5rem;
    line-height: 1.5;
    opacity: 0.8;
}

@media (max-width:700px) {
    .mobileApp {
        font-size: 35px;
    }

    .mobileParag {
        font-size: 1.3rem;
    }
}


.appImage {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.appImage img {
    width: 550px;
}

@media (max-width:1100px) {
    .appImage img {
        width: 500px;
    }
}

@media (max-width:1000px) {
    .appImage img {
        width: 450px;
    }
}


@media (max-width:500px) {
    .appImage img {
        width: 400px;
    }
}

@media (max-width:400px) {
    .appImage img {
        width: 300px;
    }

    .mobileApp {
        font-size: 30px;
    }

    .mobileParag {
        font-size: 1.2rem;
    }

    .downloadApp {
        flex-direction: column;
    }

    .downloadApp img {
        margin-bottom: 8px;

    }

}

@media (max-width:300px) {
    .appImage img {
        width: 250px;
    }
}

@media (max-width:900px) {
    .appImage {
        align-items: flex-start;
        height: 50vh;
    }
}