.transactionsWrapper {
    box-shadow:
        rgba(0, 0, 0, 0.25) 0px 0px 15px;
    margin-top: 64px;
    width: auto;
    height: auto;
    /* padding: 16px; */
    aspect-ratio: 1/1;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}

@media (max-width:660px) {
    .transactionsWrapper {
        width: 90%;
    }
}

.transactionstTitleContainer {
    margin: 0px;
    background-color: var(--primary);
    padding: 16px;
    border-radius: 8px 8px 0px 0px;
    color: white;
}

.transactionsContainer {
    padding: 16px;
    height: 500px;
    border-radius: 0px 0px 8px 8px;
    overflow-y: auto;
}

.transactionsContainer::-webkit-scrollbar {
    display: none;
}

.transactionsTitle {
    font-size: 30px;
    font-weight: 700;
    margin: 0px;
}

.transactionsInfoContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* .transactionsInfoContainer div {
    margin-right: 16px;
} */