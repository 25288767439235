.SideSignupWrapper {
    height: 100vh;
    background-color: var(--secondary);
}

.SideSignupContainer {
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.SideSignupContainer h1 {
    color: #fff;
    font-size: 50px;
    margin-bottom: 0;
}

.SideSignupContainer p {
    font-size: 25px;
    color: white;
    opacity: 0.8;
    text-align: center;
}


.SignupWrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f5fcfa;
}

.SignupContainer {
    position: relative;
    background-color: white;
    width: 60%;
    min-height: 70vh;
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.SignupContainerChildOne {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.SignupArrowBack {
    position: absolute;
    top: 40px;
    margin-left: 24px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.SignupContainer .SignupContainerChildOne h1 {
    margin-bottom: 16px;
}

form {
    width: 70% !important;
}

@media (max-width:700px) {
    .SignupContainer {
        width: 85%;
    }

    form {
        width: 90% !important;
    }
}

@media (max-width:500px) {
    .SignupContainer {
        width: 95%;
    }

    form {
        width: 90% !important;
    }
}

h1 {
    margin-bottom: 32px;
    color: var(--secondary)
}



.submitButton {
    padding: 16px !important;
    width: 100%;
    border-radius: 100px !important;
    background-color: var(--primary) !important;
}

.date {
    background-color: none;
    height: 40px;
    width: 100%;
    font-size: 16px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #848484;
    color: #848484;
    cursor: pointer
}

@media (max-width:1250px) {
    .SignupArrowBack {
        top: 25px;
    }

    .SideSignupContainer h1 {
        font-size: 40px;
    }

    .SideSignupContainer p {
        font-size: 20px;
    }

}

@media (max-width:1000px) {
    .SideSignupContainer h1 {
        font-size: 35px;
    }

    .SideSignupContainer p {
        font-size: 15px;
    }

}