.cryptoDetailWrapper {
    background-color: var(--secondary);
    /* margin-top: -128px; */
}

.cryptoDetailWrapper:nth-child(2n+2) {
    background-color: var(--secondary2);
}

.cryptoWrapper {
    display: flex;
    /* flex-direction: row; */
    width: 100%;
    overflow: auto;
}

.cryptoDetailContainer {
    padding: 8px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

}

.cryptoHeaderContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.cryptoLogoName {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.cryptoLogo {
    width: 25px;
    height: 25px;
    aspect-ratio: 1/1;
    border-radius: 100px;
    background-repeat: no-repeat;
    background-size: 100%;
    /* background-color: red; */
    margin-right: 4px;
}

.cryptoName,
.cryptoCurrency {
    margin: 0;
    color: white;
}

.cryptoName span {
    text-transform: uppercase;
}

.cryptoBodyContainer {
    display: flex;
    flex-direction: column;

}

.change,
.volume,
.high,
.low {
    margin-bottom: 4px;
    color: white;
    opacity: 0.7;
    font-size: 12px;
    text-transform: uppercase;
}

.negChange {
    margin: 0;
    color: red;
    font-weight: 600;
}

.posChange {
    font-weight: 600;
    margin: 0;
    color: green
}

.cryptoVolume,
.cryptoChange {
    margin-top: 10px;
}

.volumeValue,
.highValue,
.lowValue {
    margin: 0;
    font-weight: 500;
    color: white;
}

.cryptoFooterContainer {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 32px 0px 0px;
}