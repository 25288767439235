.featuresWrapper {
    background-color: var(--secondary);
    height: 22vh;
    border-left: 8px solid white;
    border-right: 8px solid white;
    margin-bottom: 8px;
}

.featuresContainer {
    padding: 8px;
    height: 22vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.featuresContainerTwo {
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    text-align: center;
}

.featuresTitleTwo {
    color: #074744;
    font-weight: 500;
    font-size: 2.5rem;
    letter-spacing: -0.02rem;
    margin-bottom: 64px;
}


@media (max-width:600px) {
    .featuresTitleTwo {
        font-size: 2.1rem;
    }
}

.featureTitle {
    margin: 0;
    font-weight: 500;
    font-size: 20px;
    color: #d97201;
    text-decoration: underline;
}

.featureSubTitle {
    color: white;
    font-size: 16px;
}

.technologyTitle {
    font-weight: 500;
    font-size: 1.8rem;
    color: #074744;
}

.technologyContainer {
    padding: 16px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media (max-width:600px) {
    .technologyContainer {
        height: 40vh;
        margin-bottom: 16px;
    }
}

.technologySubTitle {
    opacity: 0.8;
    font-weight: 500;
    margin-top: 0;
    font-size: 1.3rem;
    text-align: center;
}