.profileWrapper {
    box-shadow:
        rgba(0, 0, 0, 0.25) 0px 0px 15px;
    margin-top: 64px;
    width: 400px;
    height: auto;
    aspect-ratio: 1/1;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}

/* .profileContainer {
    margin-top: 8px;
} */

.profileTitleContainer {
    margin: 0px;
    background-color: var(--primary);
    padding: 16px;
    border-radius: 8px 8px 0px 0px;
    color: white;
}

.profileTitle {
    font-size: 30px;
    font-weight: 700;
    margin-top: 0px;
    margin-bottom: 0px;
}

.emailInputContainer {
    display: flex;
}

.nameInputContainer div p,
.emailInputContainer p,
.usernamePassInputContainer div p,
.walletInputContainer p {
    margin-bottom: 8px;
    /* opacity: 0.5; */
    font-size: 14px;
    font-weight: 600;
}

.walletInputContainer {
    margin-left: 16px;
}

.emailInputContainer {
    margin-top: 0px;
}

.nameInputContainer {
    display: flex;
}

.usernamePassInputContainer {
    /* margin-top: 12px; */
    display: flex;
}