.SigninWrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f5fcfa;
}

.SigninContainer {
    position: relative;
    background-color: white;
    width: 60%;
    min-height: 55vh;
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}


.SigninContainerChildOne {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}



.SigninArrowBack {
    position: absolute;
    top: 40px;
    margin-left: 24px;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.SideSigninWrapper {
    height: 100vh;
    background-color: var(--secondary)
}

.SideSigninContainer {
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.SideSigninContainer h1 {
    color: #fff;
    font-size: 50px;
    margin-bottom: 0;
}



.SideSigninSubTitle {
    font-size: 25px;
    color: white;
    opacity: 0.8;
    text-align: center;
}

@media (max-width:1250px) {
    .SigninArrowBack {
        top: 30px;
    }

    .SideSigninContainer h1 {
        font-size: 45px;
    }

    .SideSigninSubTitle {
        font-size: 20px;
    }

}

@media (max-width:1000px) {
    .SideSigninContainer h1 {
        font-size: 40px;
    }

    .SideSigninSubTitle {
        font-size: 15px;
    }

}

.SideSigninSignup {
    margin-top: 32px;
    font-size: 16px;
    color: black;
    /* opacity: 0.8; */
}



form {
    width: 70% !important;
}

@media (max-width:700px) {
    .SigninContainer {
        width: 85%;
    }

    form {
        width: 90% !important;
    }
}

@media (max-width:500px) {
    .SigninContainer {
        width: 95%;
    }

    form {
        width: 90% !important;
    }
}

h1 {
    margin-bottom: 64px;
    color: var(--secondary)
}


.submitButton {
    padding: 16px !important;
    width: 100% !important;
    border-radius: 100px !important;
    background-color: var(--primary) !important;
}