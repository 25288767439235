:root {
    --primary: #3867d6;
    --secondary: #2C4387;
    --secondary2: #314A94;
    --button1: #454655;
}

@font-face {
    font-family: roboto;
    src: url(../src/assets/fonts/Roboto-Regular.ttf);
}

body {
    margin: 0px;
    font-family: roboto;
}

body::-webkit-scrollbar {
    display: none;
}