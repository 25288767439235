.cryptoMarketLogoName {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.cryptoMarketLogo {
    width: 35px;
    height: 35px;
    aspect-ratio: 1/1;
    border-radius: 100px;
    background-repeat: no-repeat;
    background-size: 100%;
    /* background-color: red; */
    margin-right: 4px;
}

.cryptoMarketName {
    font-size: 18px;
    margin: 0;
    margin-left: 8px;
    color: black;
}

.cryptoMarketName span {
    text-transform: uppercase;
}

.negChange {
    margin: 0;
    color: red;
    font-weight: 600;
}

.posChange {
    font-weight: 600;
    margin: 0;
    color: green
}

.search {
    margin-top: 24px;
    /* background-color: red; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.search input {
    width: 50%;
    height: 50px;
    font-size: 18px;

    padding-left: 16px;
    border: 1px solid #c4c4c4;
}