.bodyWrapper {
    height: 83.2vh;
    background-color: var(--primary)
}

.bodyContainer {
    padding: 128px 32px 128px 32px;
    /* margin-top: 64px; */
    display: flex;
    flex-direction: column;
    color: #fff;
}

.bottom {
    color: white !important;
    border-bottom: 2px white solid !important;
    border-radius: 0px !important;
    text-transform: capitalise !important;
    box-shadow: 0px !important;
    margin-left: 64px !important;
}

.createAccount {
    color: #fff !important;
    background-color: var(--button1) !important;
    border-color: var(--button1) !important;
    padding: 16px 40px 16px 40px !important;
    border-radius: 100px !important;
    text-transform: capitalize !important;
    box-shadow: 0px 0px 0px !important;
    font-size: 16px !important;
}

.buttonsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.bodyTitle {
    font-size: 4rem;
    font-weight: 400;
    margin: 0px 0px 16px 0px;
}

.bodyDescription {
    margin-top: 0;
    margin-bottom: 48px;
    font-size: 1.5rem;
    font-weight: 400;
    opacity: 75%;

}

@media (max-width:1250px) {
    .bodyContainer {
        padding: 64px 32px 0px 32px;
    }
}

@media (max-width:905px) {
    .bodyTitle {
        font-size: 3.5rem;
    }
}

@media (max-width:685px) {
    .bodyTitle {
        font-size: 3rem;
    }

    .bodyDescription {
        font-size: 1.3rem;
    }
}

@media (max-width:596px) {
    .bodyTitle {
        font-size: 2.5rem;
    }

}

@media (max-width:414px) {
    .bodyContainer {
        padding: 64px 32px 80px 32px;
    }

    .bodyDescription {
        font-size: 1.1rem;
    }
}

@media (max-width:470px) {
    .bodyContainer {
        padding: 128px 32px 64px 32px;

    }

    .buttonsContainer {
        flex-direction: column;
    }

    .bottom {
        margin-left: 0px !important;
        margin-top: 16px !important;
    }

    .createAccount {
        margin-top: 16px !important;
    }
}