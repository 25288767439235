.depositeWrapper {
    box-shadow:
        rgba(0, 0, 0, 0.25) 0px 0px 15px;
    margin-top: 64px;
    width: 400px;
    height: auto;
    /* aspect-ratio: 1/1; */
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}

.myWalletContainer {
    margin-top: 32px;
    width: 100%;
}

.depositeTitleContainer {
    margin: 0;
    background-color: var(--primary);
    padding: 16px;
    border-radius: 8px 8px 0px 0px;
    color: white;
}

.depositeTitle {
    font-size: 30px;
    font-weight: 700;
    margin: 0px;

}

.depositeAmountContainer p {
    opacity: 0.5;
    font-size: 14px;
    font-weight: 600;
}

.formContainer {
    width: 100% !important;
}


.depositeAmountContainer {
    margin-top: 32px;
}

.depositeCheckboxContainer {
    margin-top: 32px;
}

.depositeSendContainer {
    margin-top: 32px;
}

.depositeSubmitButton {
    border-radius: 10px !important;
    background-color: #032E29 !important;
}

.inputDisabled {
    height: 30px;
    width: 96.5%;
    font-size: 18px;
    padding-left: 8px;
    color: black;
}

.copyButton {
    margin-top: 16px !important;
    width: 100% !important;
    border-radius: 100px !important;
    background-color: var(--secondary) !important;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}