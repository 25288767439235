h2 {
    color: var(--primary);
}

.emailTo {
    color: var(--secondary);
    font-weight: 700;
}

.css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
    padding: 0 !important;
}

.css-twia2z-MuiPaper-root-MuiDialog-paper {
    border-radius: 16px !important;
}

.css-ohyacs {
    padding: 0 !important;
}

.ContactUsSubmitButton {
    margin-top: 12px !important;
    width: 100% !important;
    border-radius: 100px !important;
    background-color: var(--secondary) !important;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}

.ContactUsTitleContainer {
    margin: 0px;
    background-color: var(--primary);
    padding: 16px;
    border-radius: 8px 8px 0px 0px;
    color: white;
}

.ContactUsTitleContainer h2 {
    color: white;
    margin: 0;
}