.footerWrapper {
    margin-top: 60px;
    margin-bottom: 0;
    height: auto;
    background-color: var(--primary);
    padding: 4px;
    text-align: center;
}

.floatingContainer {
    margin-top: -5%;
    width: 100%;
    height: 25vh;
    background-color: var(--secondary);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.floatingContainer p {
    font-size: 2rem;
    color: white;
}

@media (max-width:900px) {
    .floatingContainer p {
        /* margin-bottom: 0; */
        font-size: 1.7rem;
    }
}

@media (max-width:700px) {
    .floatingContainer p {
        margin-bottom: 0;
    }

    .floatingContainer {
        flex-direction: column;

    }
}

@media (max-width:500px) {
    .floatingContainer p {
        margin-bottom: 0;
        font-size: 1.5rem;
    }
}

@media (max-width:360px) {
    .floatingContainer {
        margin-top: -15%;
    }

    .floatingContainer p {
        margin-bottom: 0;
        font-size: 1.1rem;
    }
}

.socialMediaContainer {
    display: flex;
    flex-direction: column;
    padding: 0px 32px;
}

.socialMediaContainer div {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.socialMediaContainer div p {
    margin: 0;
    color: white;
    margin-left: 8px;
}

.FooterDownloadAppContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
}

.apple {
    margin-right: 16px;
}

.FooterDownloadApp {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 128px;
}

@media (max-width:600px) {
    .help {
        margin-top: 16px;
    }

    .legal {
        margin-top: 16px;
        padding: 0px 32px;

    }
}

@media (max-width:400px) {
    .FooterDownloadApp {
        flex-direction: column;
    }

    .apple {
        margin-right: 0px;
        margin-bottom: 8px;
    }

}