.blogWrapper {
    width: 100%;
    height: 100vh;
    background-color: #f5fcfa;
    display: flex;
    align-items: center;
    justify-content: center;
}

.blogTitle {
    width: 100%;
    text-align: center;
}

.blogTitle p {
    color: #031a1d;
    font-size: 2.5rem;
    font-weight: 500;
    letter-spacing: -0.02rem;
}

.scrollBlogContainer {
    width: 100%;
    display: flex;
    overflow-x: scroll;
    /* white-space: nowrap; */
}

.scrollBlogContainer::-webkit-scrollbar {
    display: none;
}

.blogContainer {
    margin-right: 16px;
    background-color: white;
    height: 50vh;
    width: 450px !important;
    /* display: flex; */
    /* flex-wrap: wrap; */
    /* flex-direction: column; */
    border-bottom: 8px solid #eafaf6;
}

.blogContainer p {
    margin: 0;
    padding: 16px 16px 0px 16px;
}

.blogContainer h2 {
    margin: 0;
    padding: 16px;
    font-weight: 500;
    height: 110px;
}

.footerNewsContainer {
    display: flex;
    justify-content: space-between;
}

.footerNewsContainer h5 {
    margin: 0;
    padding-right: 16px;
    color: #031a1d;
}

.blogContainer a {
    padding-left: 16px;
    text-decoration: none;
    color: orange;
}

@media (max-width:1250px) {
    .blogContainer h1 {
        font-size: 26px;
        height: 80px;

    }
}

@media (max-width:900px) {
    .blogWrapper {
        height: 130vh;
        /* margin-top: 64px; */
    }

    .blogContainer {
        margin-top: 8px;
    }
}

@media (max-width:1050px) {
    .blogContainer h1 {
        font-size: 24px;
    }
}

@media (max-width:600px) {
    .blogWrapper {
        height: 100vh;
        /* margin-top: 64px; */
    }
}

@media (max-width:500px) {
    .blogTitle p {
        font-size: 2rem;
    }
}

@media (max-width:350px) {
    .blogTitle p {
        font-size: 1.8rem;
    }
}